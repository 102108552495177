import * as React from 'react';
import { FormattedMessage } from '../../localization/index';

const iconEmail = require('../../assets/imgs/icon-email.png');
const iconTel = require('../../assets/imgs/icon-tel.png');
const ccMaster = require('../../assets/imgs/cc-master.png');
const ccVisa = require('../../assets/imgs/cc-visa.png');

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <h1>
          <FormattedMessage id="footer-h1" defaultMessage="Experience Funbox on Any Device"></FormattedMessage>
        </h1>
        {/* <div className="logo-wrap">
          <svg className="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.3 126.4">
            <path
              className="st0"
              d="M95.3,0.9c9.5,8.8,18.9,17.6,28.4,26.5c0.4-0.7,0.3-1.3,0.3-1.8c0-7.8,0-15.6,0-23.4c0-1.2,0.3-1.7,1.6-1.6
                          c1.5,0.1,3,0.1,4.5,0c0.9,0,1.3,0.3,1.3,1.2c-0.1,1.3,0,2.5,0,3.8c0,12.2,0,24.3,0,36.5c0,0.5,0,1.1,0,2c-1.3-1.2-2.3-2.1-3.3-3
                          c-8.2-7.6-16.3-15.2-24.5-22.8c-0.3-0.3-0.6-0.8-1.1-0.7c-0.6,0.2-0.3,0.9-0.3,1.3c0,7.7,0,15.5,0,23.2c0,1.4-0.3,2-1.8,1.9
                          c-1.4-0.1-2.8-0.1-4.1,0c-0.9,0-1.2-0.3-1.2-1.1c0-0.4-0.1-0.9-0.1-1.3c0-13,0-26.1,0-39.1C95,2,94.8,1.5,95.3,0.9z"
            />
            <path
              className="st0"
              d="M131.3,77.3c-1.3,2.1-2.3,3.8-3.4,5.6c-3.2,5.1-6.3,10.3-9.6,15.3c-0.7,1.1-0.1,1.7,0.3,2.4
                          c3.9,6.3,7.9,12.6,11.8,18.8c0.2,0.4,0.4,0.8,0.8,1.5c-2.6,0-5.1,0-7.5,0c-0.6,0-0.9-0.5-1.2-1c-2.9-4.5-5.7-9-8.6-13.5
                          c-1-1.6-1-1.6-2,0c-2.8,4.5-5.7,8.9-8.5,13.4c-0.5,0.8-1.1,1.2-2,1.2c-2.1-0.1-4.3,0-6.7,0c0.7-1.8,1.7-3,2.6-4.4
                          c3.5-5.4,7-10.7,10.6-16.1c0.7-1,0.7-1.6,0-2.7c-4.2-6.4-8.4-12.8-12.6-19.2c-0.2-0.3-0.4-0.7-0.7-1.3c2.7,0,5.1,0,7.6,0
                          c0.6,0,0.8,0.6,1,1c2.9,4.6,5.9,9.1,8.7,13.8c0.8,1.3,1.2,0.9,1.8-0.1c2.9-4.6,5.8-9.1,8.7-13.6c0.3-0.5,0.6-1.1,1.2-1.1
                          C126.1,77.3,128.6,77.3,131.3,77.3z"
            />
            <path
              className="st0"
              d="M11,120.7c-3.2,0-6.4,0-9.6,0c-1,0-1.4-0.3-1.3-1.3c0.1-1.5,0.1-3,0-4.5c-0.1-1.3,0.4-1.6,1.6-1.6
                          c5.9,0.1,11.7,0,17.6,0c3.2,0,5.1-1.8,5.1-5c0-3.4-1.8-5.3-5.1-5.3c-5.9,0-11.7,0-17.6,0c-1.2,0-1.7-0.3-1.6-1.6
                          c0.1-1.5,0.1-3,0-4.5c-0.1-1.2,0.3-1.5,1.5-1.5c4.8,0.1,9.5-0.1,14.3,0.1c3.2,0.1,4.9-1.8,5-5.2c0.1-3.6-1.8-5.6-5-5.6
                          c-4.7,0-9.4-0.1-14.1,0c-1.4,0-1.7-0.4-1.6-1.7c0.1-1.5,0-3.1,0-4.6c0-0.7,0.1-1.2,1-1.2c5.7,0.1,11.4-0.4,17.1,0.2
                          c5.7,0.7,9.3,4.8,9.8,10.6c0.2,3.1,0.2,6.2-1.6,9c-0.5,0.7-0.2,1,0.4,1.5c5.8,4.3,6.2,11.8,3,17.1c-2,3.3-5.3,4.8-9.1,5
                          C17.4,120.8,14.2,120.7,11,120.7C11,120.7,11,120.7,11,120.7z"
            />
            <path
              className="st0"
              d="M47.7,16c0-4.6,0.1-9.2,0-13.8c0-1.4,0.4-1.7,1.7-1.6c1.3,0.1,2.5,0.1,3.8,0C54.6,0.5,55,1,54.9,2.4
                          c-0.1,8.4,0,16.8,0,25.2c0,1.8,0.1,3.6,0.9,5.3c1.1,2.3,2.8,4,5.4,4.3c4,0.6,7.8,0.3,10.4-3.4c1.3-1.8,1.4-3.9,1.4-6
                          c0-8.3,0-16.6,0-24.9c0-2.4,0-2.4,2.3-2.4c1.2,0,2.4,0.1,3.6,0c1.2-0.1,1.5,0.4,1.5,1.5c0,8.7,0,17.4,0,26.1
                          c-0.1,5.4-1.7,10.1-6.4,13.3c-2,1.3-4.2,2.1-6.5,2.4c-5.7,0.6-11.3,0.4-15.8-4c-2.7-2.7-3.9-6.2-4-9.9C47.6,25.3,47.7,20.7,47.7,16
                          C47.7,16,47.7,16,47.7,16z"
            />
            <path
              className="st0"
              d="M13.9,19.1c3.9,0,7.9,0,11.8,0c1.1,0,1.6,0.3,1.5,1.5c-0.1,1.5-0.1,3,0,4.5c0.1,1.1-0.4,1.4-1.4,1.4
                          c-5.4,0-10.7,0-16.1,0c-1.3,0-1.8,0.3-1.8,1.7c0.1,4.8,0,9.5,0.1,14.3c0,1.4-0.4,1.7-1.7,1.7C4.8,44,3.4,44,2,44.1
                          c-1.1,0.1-1.4-0.3-1.4-1.4c0-7.4,0-14.8,0-22.2c0-1,0.3-1.3,1.3-1.3C5.9,19.2,9.9,19.1,13.9,19.1z"
            />
            <path
              className="st0"
              d="M15,7.9c-4.3,0-8.6,0-12.9,0c-1.2,0-1.5-0.4-1.5-1.5c0.1-1.5,0.1-3.1,0-4.6c0-0.9,0.3-1.2,1.2-1.2
                          c8.8,0,17.7,0,26.5,0c0.9,0,1.3,0.3,1.2,1.3c-0.1,1.5-0.1,3.1,0,4.6c0.1,1.1-0.3,1.4-1.4,1.4C23.7,7.9,19.4,7.9,15,7.9z"
            />
            <path
              className="st1"
              d="M54.7,99.9l0-12.3l-12.4-6.9c0,0-0.1,7.8-0.1,14.7c0,6.7,0.1,22,0.1,22l12.4-6.9L54.7,99.9
                          C54.7,99.9,54.7,99.9,54.7,99.9z"
            />
            <polygon
              className="st2"
              points="86.7,91.7 86.7,91.7 86.7,91.6 86.6,91.6 52.9,71.7 42.2,80.7 73.3,99 42.2,117.4 52.9,126.4 
                          86.6,106.5 86.7,106.5 "
            />
            <polygon id="XMLID_376_" className="st3" points="54.7,90.7 54.7,88 42.2,80.7 " />
            <polygon id="XMLID_384_" className="st3" points="54.7,107.3 54.7,110.1 42.2,117.4 " />
            <path
              className="st0"
              d="M65.4,61.8c-19.6,0-39.1,0-58.7,0c-5.3,0-7-0.1-6.7-0.5c0.4-0.5,0.3-1,0-1.5c-0.1-0.3,1.4-0.4,5.3-0.4
                        c40.1,0,80.2,0,120.3,0c4.3,0,5.7,0.1,5.6,0.4c-0.2,0.5-0.3,1,0,1.5c0.3,0.3-1.6,0.4-6.4,0.4C105,61.8,85.2,61.8,65.4,61.8z"
            />
          </svg>
        </div> */}
        <div className="footer-wrap">
          <div className="footer-left">
            <div className="customer-care">
              <h3>
                <FormattedMessage id="customer-care" defaultMessage="Customer Care"></FormattedMessage>
              </h3>
              <div className="list-wrap">
                <div className="each">
                  <div className="icon">
                    <img src={iconTel} />
                  </div>
                  <div className="details">
                    <a href="tel:0805985498">
                      0805 98 5498 (<FormattedMessage id="france" defaultMessage="France"></FormattedMessage>)
                    </a>
                  </div>
                </div>
                <div className="each">
                  <div className="icon">
                    <img src={iconTel} />
                  </div>
                  <div className="details">
                    <a href="tel:900751051">
                      900 751 051 (<FormattedMessage id="span" defaultMessage="Spain"></FormattedMessage>)
                    </a>
                  </div>
                </div>
                <div className="each">
                  <div className="icon">
                    <img src={iconEmail} />
                  </div>
                  <div className="details">
                    <a href="mailto:help@.online">help@fun-box.net</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="company-info">
              <p>
                <strong className="text-gr">AMB GLOBAL DIGITAL SOLUTIONS LTD</strong>
                <br />
                1 Maiou 3, 2610 Agios Epifanios Oreinis, Cyprus
                <br />
                <a href="https://www.ambglobaldigital.com" target="_blank">
                  www.ambglobaldigital.com
                </a>
              </p>
            </div>
            <div className="copyright">
              {/* <script>document.write(new Date().getFullYear());</script> © Funbox <FormattedMessage id="all-rights-reserved" defaultMessage="All Rights Reserved"></FormattedMessage> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="quick-links">
            <a className="each" href="https://portal.fun-box.net/privacy-policy" target="_blank">
              <FormattedMessage id="privacy-policy" defaultMessage="Privacy Policy"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.fun-box.net/refund-policy" target="_blank">
              <FormattedMessage id="refund-policy" defaultMessage="Refund Policy"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.fun-box.net/terms-and-conditions" target="_blank">
              <FormattedMessage id="terms-conditions" defaultMessage="Terms & Conditions"></FormattedMessage>
            </a>
            <a className="each" href="https://portal.fun-box.net/faq" target="_blank">
              <FormattedMessage id="faq" defaultMessage="FAQ"></FormattedMessage>
            </a>
          </div>
          <div className="credit-card-icon">
            <img src={ccMaster} />
            <img src={ccVisa} />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
