import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RDS, RootState, identifyStrategy, strategy } from 'ouisys-engine/strategy';
import { useSelector } from 'react-redux';
import { Disclaimers, LanguageSwitcher, PricePoint, TopLegals, defineMessages } from 'ouisys-component-library';
import FLOWS from './__doNotModify/includedFlows';
import COMP from './__doNotModify/includedComponents';
import ProvidersWrapper from './providers/ProvidersWrapper';
import { FormattedMessage, legals } from './localization';
import Loader from './components/Loader';
import { useRootContext } from './providers/RootContext';
import { tracker } from 'ouisys-engine/utilities/tracker';
import PreLander from './components/PreLander';
import PaymentStatus from './components/PaymentStatus';
import { useDispatch } from './__doNotModify/store';
import searchToObject from './utils/searchToObject';
import SwitchLang from './components/SwitchLang';
import './Root.scss';
import './2024.scss';
import Footer from './components/Footer';

import price from './price';

// Define error messages here
const defaultMessages = defineMessages({
  InvalidMSISDN: {
    id: 'InvalidMSISDN',
    defaultMessage: 'This mobile number is incorrect, try again'
  },
  AlreadySubscribed: {
    id: 'AlreadySubscribed',
    defaultMessage: 'You are already a subscriber!'
  },
  UnknownError: {
    id: 'UnknownError',
    defaultMessage: 'An unknown error occurred.'
  },
  InvalidPIN: {
    id: 'InvalidPIN',
    defaultMessage: 'Please enter the correct PIN that you received on your phone'
  },
  SessionBlocked: {
    id: 'SessionBlocked',
    defaultMessage: 'Session Blocked!'
  },
  TooEarly: {
    id: 'TooEarly',
    defaultMessage: 'You must first submit a mobile number'
  },
  didnt_receive_pin_yet: {
    id: 'didnt_receive_pin_yet',
    defaultMessage: "Didn't receive PIN yet?"
  }
});

const Root = () => {
  const { currentState } = useSelector((state: RootState) => state.strategy);
  const { locale, switchLang, skipPrelander, phoneInputRef, setShake, d_theme } = useRootContext();
  const [showOverlay, setShowOverlay] = useState(false);
  const dispatch = useDispatch();
  const [isModal, setModal] = useState(false);
  const urlParams = searchToObject();
  const [paymentStatus, setPaymentStatus] = useState(false);

  //image library
  const imgBGm = require('./assets/imgs/bg-M.png');
  const imgBGfooter = require('./assets/imgs/footer.png');
  const imgMovioScene = require('./assets/imgs/movio-scene.png');
  const logo = require('./assets/imgs/logo.png');
  const imgPhone = require('./assets/imgs/phone.png');
  const imgScroll = require('./assets/imgs/scroll.gif');
  const imgStrip = require('./assets/imgs/strip.png');
  const iconHelp = require('./assets/imgs/icon-support.png');

  const slider01 = require('./assets/imgs/news.jpg');
  const slider02 = require('./assets/imgs/lifestyle.jpg');
  const slider03 = require('./assets/imgs/sport.jpg');
  const slider04 = require('./assets/imgs/entertainment.jpg');
  const slider05 = require('./assets/imgs/elearning.jpg');

  const [isActive, setIsActive] = useState(false);

  // when user clicks on the creative area, we show an overlay
  // so the user will focus on the subscription form
  // Function to handle the div focus, now optimized with useRef and useState
  const divFocus = useCallback(() => {
    tracker.customEvent('creativeArea', 'click', 'form-focus');
    setShowOverlay(true);
    setShake(true);

    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
    // Trigger a vibration if available
    if ('vibrate' in navigator) {
      navigator.vibrate([100, 30, 100, 30, 100]);
    }

    // Reset shake effect after 1 second (or the duration of your shake animation)
    setTimeout(() => setShake(false), 1000);
  }, []);

  const paymentPage = useRef(null);

  useEffect(() => {
    if (paymentPage) {
      paymentPage.current.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [isActive]);

  const identifyStrategyEffect = useCallback(() => {
    dispatch(identifyStrategy());
  }, [dispatch]);

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(identifyStrategy());
    checkPaymentStatus();
    //for zendesk widget
    if (typeof window !== 'undefined') {
      const script = document.createElement('script');
      script.id = 'ze-snippet';
      script.src = 'https://static.zdassets.com/ekr/snippet.js?key=aa3a0113-0ca1-4929-b080-7d14d1edd977';
      script.async = true;
      document.head.appendChild(script);
    }
    //zendesk widget end
  }, []);

  const handleClick = () => {
    setIsActive(true);
  };

  // Show or hide thank you
  const checkPaymentStatus = () => {
    if (urlParams['payment-status']) {
      setPaymentStatus(urlParams['payment-status'] === 'true');
    }
  };

  return (
    <div>
      <div className={`bx-guides bx-${locale} theme-${d_theme === 'dark' ? 'dark' : 'light'}`}>
        <div className={`bx-container bx-modal-${isModal} bx-overlay-${showOverlay}`}>
          {!skipPrelander && <PreLander onClick={divFocus} />}
          {/* Payment Status */}
          {paymentStatus && <PaymentStatus />}
          <div className={`creativeArea ${paymentStatus !== null ? '' : 'hide'}`}>
            <a href="#footer" className="srollToBottom" onClick={scrollToBottom}>
              <img src={iconHelp} />
            </a>
            <header>
              <div className="brand">
                <img src={logo} className="logo" />
              </div>
              <SwitchLang locale={locale} switchLang={switchLang} />
              <a
                className="btn"
                href="https://max.moviotv.online/login"
                target="_blank"
                onClick={() => {
                  tracker.advancedInFlow('Flow', 'Login');
                }}
              >
                <FormattedMessage id="login" defaultMessage="Login"></FormattedMessage>
              </a>
            </header>
            <main>
              <section className="sec1">
                <h1>
                  <FormattedMessage
                    id="s1-h1"
                    defaultMessage="Get Endless Entertainment, News, Sports and Premium Content"
                  ></FormattedMessage>
                </h1>
                <p>
                  <FormattedMessage
                    id="s1-p"
                    defaultMessage="A Universe of Video Content Awaits You"
                  ></FormattedMessage>
                </p>
                <button
                  className="btn btn-red open-payment"
                  onClick={() => {
                    handleClick();
                    tracker.advancedInPreFlow('startTrial-clicked');
                  }}
                >
                  <FormattedMessage id="startTrial" defaultMessage="start trial"></FormattedMessage>
                </button>
                <div className="price-point">
                  €{price.trial} / <FormattedMessage id="daysTrial" defaultMessage="3 Days Trial"></FormattedMessage>
                  <br />€{price.subscription} /{' '}
                  <FormattedMessage id="monthlySubscription" defaultMessage="Monthly Subscription"></FormattedMessage>
                  <br />
                  <FormattedMessage
                    id="trialTC"
                    defaultMessage="Trial for eligible new members only • Recurring billing • Cancel at any time"
                  ></FormattedMessage>
                </div>

                <div className="slider-wrap">
                  <img src={slider01} className="slider-img" />
                  <img src={slider02} className="slider-img" />
                  <img src={slider03} className="slider-img" />
                  <img src={slider04} className="slider-img" />
                  <img src={slider05} className="slider-img" />
                </div>
                <img src={imgScroll} className="scroll" />
              </section>
              <section className="sec2">
                <div className="container">
                  <img src={imgPhone} className="phone" />
                  <div className="content">
                    <h1>
                      <FormattedMessage
                        id="s2-h1"
                        defaultMessage="Watch Your Favourite Videos on Any Device"
                      ></FormattedMessage>
                    </h1>
                    <div className="each">
                      {/* <h2 className="text-gr"><FormattedMessage id="s2-h2-1" defaultMessage="mobile tv"></FormattedMessage></h2> */}
                      <p>
                        <FormattedMessage
                          id="s2-p-1"
                          defaultMessage="Stay up-to-date with the latest news, events and lifestyle content from around the world."
                        ></FormattedMessage>
                      </p>
                    </div>
                    <div className="each">
                      {/* <h2 className="text-gr"><FormattedMessage id="s2-h2-2" defaultMessage="videos-on-demand"></FormattedMessage></h2> */}
                      <p>
                        <FormattedMessage
                          id="s2-p-2"
                          defaultMessage="Watch engaging and informative videos covering top stories, politics, business, sports, lifestyle  and more."
                        ></FormattedMessage>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="sec3">
                <h1 className="text-gr">
                  <FormattedMessage id="s3-h1" defaultMessage="The Amazement is Just About to Start"></FormattedMessage>
                </h1>
                <p>
                  <FormattedMessage
                    id="s3-p"
                    defaultMessage="Never Miss a Beat - Subscribe to Get Unlimited Timely Videos"
                  ></FormattedMessage>
                </p>
                <img src={imgMovioScene} className="movio-scene" />
                <div className="features">
                  <div className="each">
                    <h2 className="text-gr">
                      <FormattedMessage id="s3-h2-1" defaultMessage="COMPLETELY AD FREE VIDEOS"></FormattedMessage>
                    </h2>
                    <p>
                      <FormattedMessage
                        id="s3-p-1"
                        defaultMessage="No need to go through the hassle of ads. We provide an amazing experience without ads. Just enjoy watching your favourite videos."
                      ></FormattedMessage>
                    </p>
                  </div>
                  <div className="each">
                    <h2 className="text-gr">
                      <FormattedMessage id="s3-h2-2" defaultMessage="NO COMMITMENT REQUIRED"></FormattedMessage>
                    </h2>
                    <p>
                      <FormattedMessage
                        id="s3-p-2"
                        defaultMessage="Cancel your subscription anytime, and where. or contact our support team to help you with the service."
                      ></FormattedMessage>
                    </p>
                  </div>
                  <div className="each">
                    <h2 className="text-gr">
                      <FormattedMessage id="s3-h2-3" defaultMessage="ADD TO FAVOURITE LIST"></FormattedMessage>
                    </h2>
                    <p>
                      <FormattedMessage
                        id="s3-p-3"
                        defaultMessage="Add and customise what you want to watch from our videos, keep track and get weekly updates."
                      ></FormattedMessage>
                    </p>
                  </div>
                </div>
                {/* <img src={imgStrip} className="strip" /> */}
              </section>
            </main>
          </div>

          <section className={`payment-page ${isActive ? 'is-visible' : ''}`} ref={paymentPage}>
            <div className="outer-wrap">
              <div className="container">
                <div className="pp-header">
                  <div className="logo-wrap">
                    <img src={logo} className="logo" />
                  </div>
                  <button className="pp-close" onClick={() => setIsActive(false)} data-close>
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <path
                        id="Icon_material-close"
                        d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
                        transform="translate(-7.5 -7.5)"
                      />
                    </svg>
                  </button>
                </div>
                <div className="inner-wrap">
                  <div className="right">
                    <div onClick={(ev) => ev.stopPropagation()}>
                      {strategy({
                        identifyInitialState: () => <Loader />,
                        identifyFlowByMsisdn: (rds) => <COMP.IdentifyFlowByMsisdn rds={rds} />,
                        identifyFlowByOperatorSelect: () => <COMP.OperatorSelection />,
                        pinFlow: () => <FLOWS.PinFlow />,
                        moFlow: () => <FLOWS.MoFlow />,
                        moRedirFlow: () => <FLOWS.MoRedirFlow />,
                        oneClickFlow: () => <FLOWS.OneClickFlow />,
                        click2smsFlow: () => <FLOWS.Click2smsFlow />,
                        ussdFlow: () => <FLOWS.UssdFlow />,
                        creditCardFlow: () => <FLOWS.CreditCardFlow />
                      })(currentState)}
                    </div>
                  </div>
                  <div className="left">
                    <div className="each">
                      <h2>
                        <FormattedMessage id="regTerms-title-1" defaultMessage="Safe and Secure"></FormattedMessage>
                      </h2>
                      <p>
                        <FormattedMessage
                          id="regTerms-p-1"
                          defaultMessage="All user data are protected in the service.  Your information is safe and secure as we protect our member’s information by using the best security measures possible. We will not sell or rent your private information to third parties, we value your privacy."
                        ></FormattedMessage>
                      </p>
                    </div>
                    <div className="each">
                      <h2>
                        <FormattedMessage
                          id="regTerms-title-2"
                          defaultMessage="Why do we need your billing information?"
                        ></FormattedMessage>
                      </h2>
                      <p>
                        <FormattedMessage
                          id="regTerms-p-2"
                          defaultMessage="Because we are only licensed to distribute our content to certain countries, we ask that you verify your mailing address by providing us with a valid credit card number."
                        ></FormattedMessage>
                      </p>
                    </div>
                    <div className="each">
                      <h2>
                        <FormattedMessage
                          id="regTerms-title-3"
                          defaultMessage="Sign up today, here’s why:"
                        ></FormattedMessage>
                      </h2>
                      <li>
                        <FormattedMessage id="regTerms-li-1" defaultMessage="Exclusive content"></FormattedMessage>
                      </li>
                      <li>
                        <FormattedMessage id="regTerms-li-2" defaultMessage="Ad-free!"></FormattedMessage>
                      </li>
                    </div>
                    <div className="each">
                      <h2>
                        <FormattedMessage
                          id="regTerms-title-4"
                          defaultMessage="Get access now and start streaming"
                        ></FormattedMessage>
                      </h2>
                      <p>
                        <FormattedMessage
                          id="regTerms-p-3"
                          defaultMessage="We make sure to provide our members with a detailed transaction history so that they know what they are paying for. Credit card information is required to facilitate future purchases only."
                        ></FormattedMessage>
                      </p>
                    </div>
                    <div className="each">
                      <h2>
                        <FormattedMessage id="regTerms-title-5" defaultMessage="Cancellation Policy"></FormattedMessage>
                      </h2>
                      <p>
                        <FormattedMessage
                          id="regTerms-p-5"
                          defaultMessage="You can cancel your membership at any time by contacting our Customer Care Department free of charge."
                        ></FormattedMessage>
                      </p>
                      <p>
                        <FormattedMessage
                          id="regTerms-p2-5"
                          defaultMessage="The charge for your subscription to Funbox TV will appear on your credit card statement as"
                        ></FormattedMessage>{' '}
                        www.fun-box.net +33 0805 98 5498
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </section>

          {RDS.WhenLoading(null, () => (
            <div className="loading-wrapper">
              <Loader />
              <FormattedMessage id="loadingLabel" defaultMessage="Please wait..." />
            </div>
          ))(currentState?.result as RDS.RemoteDataState<unknown, unknown>)}
        </div>
        <Footer />
      </div>
    </div>
  );
};

const R = () => (
  <ProvidersWrapper>
    <Root />
  </ProvidersWrapper>
);

export default R;
