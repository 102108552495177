import * as React from 'react';
import { injectIntl, FormattedMessage } from '../../localization';
import './PaymentStatus.scss';
import { product } from 'ramda';

const tryAgain = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
  window.location.reload();
};

const PaymentStatus = () => {
  const urlParams = new URLSearchParams(window.location.search);

  // Read a specific parameter
  let userStatus = urlParams.get('user-status');
  let productUrl = urlParams.get('product-url');

  return (
    <section className="tq-page">
      {
        //if true
        userStatus === 'paymentSuccess' ? (
          <div className="cctqbox">
            <h3>
              <FormattedMessage id="congratsTitle" defaultMessage="Congratulations!" />
            </h3>
            <p>
              <FormattedMessage
                id="congratsText"
                defaultMessage="You've successfully verified your account and now have full access. Enjoy!"
              />
            </p>
            <a className="btn btn-red" href={`https://${productUrl}`} target="_blank">
              <FormattedMessage id="portalButton" defaultMessage="Explore Now" />
            </a>
          </div>
        ) : //else if
        userStatus === 'alreadySubscribed' ? (
          <div className="cctqbox">
            <h3>
              <FormattedMessage id="AlreadySubscribed" defaultMessage="You are already a subscriber!" />
            </h3>
            <a className="btn btn-red" href={`https://${productUrl}`} target="_blank">
              <FormattedMessage id="portalButton2" defaultMessage="Go to portal" />
            </a>
          </div>
        ) : (
          //else
          <div className="cctqbox">
            <h3>
              <FormattedMessage id="paymentFailTitle" defaultMessage="Verification Failed" />
            </h3>
            <p>
              <FormattedMessage
                id="paymentFailText"
                defaultMessage="There was an issue verifying your account. Please try again !"
              />
            </p>
            <button className="btn btn-red" onClick={tryAgain}>
              <FormattedMessage id="tryagain" defaultMessage="Try Again" />
            </button>
          </div>
        )
      }
    </section>
  );
};

export default injectIntl(PaymentStatus);
